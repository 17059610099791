<template>
  <v-row no-gutters class="mt-3 ml-2">
    <v-col cols="12" md="8">
      <v-col cols="12" md="12">
        <v-row no-gutters class="ml-2">
          <v-col cols="5" md="2">
            <v-menu
              v-model="fromMenu"
              full-width
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="fromDate"
                  label="From Date"
                  readonly
                  v-on="on"
                  hide-details
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fromPicker"
                @input="fromMenu = false"
                color="primary"
                :max="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="5" md="2" class="pl-2">
            <v-menu
              v-model="toMenu"
              full-width
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="toDate"
                  label="To Date"
                  readonly
                  v-on="on"
                  hide-details
                  dense
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="toPicker"
                @input="toMenu = false"
                color="primary"
                :max="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" md="2" class="pl-2">
            <v-combobox
              v-model="allincometype"
              :items="allincometypeList"
              label="Income Type"
              item-text="description"
              return-object
              required
              dense
            ></v-combobox>
          </v-col>
          <v-col cols="6" md="2" class="pl-2">
            <v-combobox
              v-model="allcashtype"
              :items="allcashtypeList"
              label="Cash Type"
              item-text="typeName"
              return-object
              required
              dense
            ></v-combobox>
          </v-col>
          <v-col cols="6" md="2" class="pl-1">
          <v-combobox
            v-model="branch"
            :items="branchList"
            label="Branch"
            item-text="description"
            return-object
            required
            dense
          ></v-combobox>
        </v-col>
          <v-col cols="2" md="1" class="pl-2 pt-1">
            <v-btn icon class="primary" @click="getSearch()" small>
              <v-icon color="white"> search </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="12">
        <v-simple-table :fixed-header="true" dense class="elevation-1" height="80vh">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center white--text primary">No.</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Receive Date</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Income</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Type</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">One Unit</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Unit Amount</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Amount</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Remark</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Date</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(income, index) in incomeList"
                :key="index"
                @click="selectOneClick(income)"
              :style="{
                backgroundColor:
                  income.incomeId == selectedOne.incomeId ? '#def3ff' : 'transparent',
              }"
              >
                <td class="text-center">{{ index + 1 }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center">{{ income.receivedDate }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-left">{{ income.inDescription }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-left primary--text">{{ income.tranDto.typeName }}({{ income.tranDto.unit }})</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-right">{{ income.tranDto.oneUnit|doubleFormat }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-right">{{ income.tranDto.unitAmount|doubleFormat }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-right">{{ income.tranDto.amount|doubleFormat }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center">{{ income.tranDto.remark }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center">{{ income.tranDto.date }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center">
                  <v-btn
                    small
                    icon
                    color="green"
                    @click="clickEditIncome(income)"
                  >
                    <v-icon>edit</v-icon></v-btn
                  >
                  <v-btn
                    small
                    icon
                    color="red"
                    @click="clickDeleteIncome(income)"
                  >
                    <v-icon>delete</v-icon></v-btn
                  >
                </td>
              </tr>
              <v-divider></v-divider>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-col>
    <v-col cols="12" md="4" >
        <v-row no-gutters class="mt-12 ml-2 mr-2">
          <v-col cols="12" md="12">
<v-menu
              v-model="receiveMenu"
              full-width
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="receiveDate"
                  label="Receive Date"
                  readonly
                  v-on="on"
                  hide-details
                  dense
                  filled
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="receivePicker"
                @input="receiveMenu = false"
                color="primary"
                :max="new Date().toISOString().substr(0, 10)"
              ></v-date-picker>
            </v-menu>
        </v-col>
          <v-col cols="12" md="12" class="mt-3">
            <v-combobox
              v-model="incometype"
              :items="incometypeList"
              label="Income Type"
              item-text="description"
              return-object
              required
              dense
              outlined
              filled
            ></v-combobox>
          </v-col>
          <v-col cols="12" md="12">
            <v-row no-gutters>
              <v-col cols="12" md="6">
            <v-combobox
              v-model="cashtype"
              :items="cashtypeList"
              label="Cash Type"
              item-text="typeName"
              return-object
              required
              dense
              outlined
              filled
            ></v-combobox>
              </v-col>
              <v-col cols="12" md="6">
                <center>
                  <span class="red--text"><h3>{{balance|doubleFormat}} ({{cashtype.unit}}) </h3></span>
                  </center>
              </v-col>
            </v-row>
          </v-col>
           <v-col cols="6" md="6">
                <v-text-field
                  v-model.number="oneUnit"
                  label="OneUnit"
                  type="number"
                  dense
                  outlined
                  filled
                />
              </v-col>
              <v-col cols="6" md="6" class="pl-3 green--text">
          <center>
          <h4>{{amount|doubleFormat}}(mmk)</h4>
          </center>
              </v-col>
          <v-col cols="12" md="12">
             <v-text-field
                  v-model.number="unitAmount"
                  label="UnitAmount"
                  type="number"
                  dense
                  outlined
                  filled
                  disabled
                />
            </v-col>
            <v-col cols="12" md="12">
            <v-textarea label="Remark" rows="2" v-model="remark" type="number" dense outlined filled></v-textarea>
            </v-col>
            <v-col cols="12" md="12" class="text-right">
              <v-btn color="primary" @click="clickSaveOrUpdate()">{{saveOrupdate}}</v-btn>
            </v-col>
            <v-col cols="12" md="12">
            <h3>Total Balance = {{totalBalance|doubleFormat}}</h3>
        </v-col>
        </v-row>
    </v-col>
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Delete </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="deleteDialog = false">
            Cancel
          </v-btn>
          <v-btn color="red" text @click="clickDeleteDialog()">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import billService from "../service/BillService";
export default {
  data: () => ({
    incomeList: [],
    selectedOne: {},
    fromPicker: new Date().toISOString().substr(0, 10),
    toPicker: new Date().toISOString().substr(0, 10),
    fromMenu: false,
    toMenu: false,
    fromDate: "",
    toDate: "",
    allincometypeList: [],
    allincometype: {},
    allcashtypeList:[],
    allcashtype:{},
    amount:0,
    remark:"",
    saveOrupdate:"SAVE",
    incometypeList: [],
    incometype: {},
    cashtypeList:[],
    cashtype:{},
    income:{},
    deleteDialog:false,
    balance:0,
    branchList:[],
    branch:{},
    receivePicker: new Date().toISOString().substr(0, 10),
    receiveMenu: false,
    receiveDate: "",
    totalBalance:0,
    oneUnit:0,
    unitAmount:0
  }),
  props: {},
  mounted: function () {
    this.receiveDate = this.formatDate(this.receivePicker);
    this.fromDate = this.formatDate(this.fromPicker);
    this.toDate = this.formatDate(this.toPicker); 
    this.getCashListMethod();
    this.getBranchListMethod();
    this.getIncomeListMethod();
  },
  methods: {
    getBranchListMethod:function(){
billService
        .getBranch()
        .then((response) => {
          var tempObj = {};
          tempObj.branchId = 0;
          tempObj.description = "All";
          this.branchList.push(tempObj);
          this.branchList.push(...response);
          this.branch = this.branchList[0];
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    clickDeleteDialog:function(){
      this.deleteDialog = false;
billService
        .deleteIncomeIncome(this.income)
        .then((response) => {
          this.getBalanceMethod();
          this.getSearch();
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    selectOneClick:function(item){
        this.selectedOne = Object.assign({},item);
    },
    clickDeleteIncome:function(item){
        this.income = Object.assign({},item);
        this.deleteDialog = true;
    },
    clickEditIncome:function(item){
        this.income = Object.assign({},item);
        var tempIncome = {};
        tempIncome.itypeId = item.itypeId;
        tempIncome.description = item.inDescription;
        this.incometype = Object.assign({},tempIncome);
        var tempType = {};
        tempType.typeId = item.tranDto.typeId;
        tempType.typeName = item.tranDto.typeName;
        tempType.unit = item.tranDto.unit;
        this.cashtype = Object.assign({},tempType);
        this.oneUnit = item.tranDto.oneUnit;
        this.unitAmount = item.tranDto.unitAmount;
        this.amount = item.tranDto.amount;
        this.remark = item.tranDto.remark;
        this.saveOrupdate = "UPDATE";
        this.receiveDate = item.receivedDate;
        this.receivePicker = this.formatPicker(this.receiveDate);
    },
    clickSaveOrUpdate:function(){
      if(Number(this.amount)>0){
      if(this.saveOrupdate != "UPDATE"){
        var tempObj = {};
        tempObj.itypeId = this.incometype.itypeId;
        var tranDto = {};
        tranDto.typeId = this.cashtype.typeId;
        tranDto.amount = this.amount;
        tranDto.oneUnit = this.oneUnit;
        tranDto.unitAmount = this.unitAmount;
        tranDto.remark = this.remark;
        tempObj.tranDto = tranDto;
        tempObj.receivedDate = this.receiveDate;
        billService
        .saveIncomeIncome(tempObj)
        .then((response) => {
          this.getBalanceMethod();
          this.getSearch();
          this.saveOrupdate = "SAVE";
          this.amount = 0;
          this.oneUnit = 0;
          this.remark = "";
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
      }else{
        this.income.itypeId = this.incometype.itypeId;
        this.income.receivedDate = this.receiveDate;
        this.income.tranDto.typeId = this.cashtype.typeId;
        this.income.tranDto.amount = this.amount;
        this.income.tranDto.oneUnit = this.oneUnit;
        this.income.tranDto.unitAmount = this.unitAmount;
        this.income.tranDto.remark = this.remark;
          billService
        .updateIncomeIncome(this.income)
        .then((response) => {
          this.getBalanceMethod();
          this.getSearch();
          this.saveOrupdate = "SAVE";
          this.amount = 0;
          this.oneUnit = 0;
          this.remark = "";
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
        }
      }
    },
    getCashListMethod:function(){
billService
        .getCashTypes()
        .then((response) => {
          this.cashtypeList.push(...response);
          this.cashtype = this.cashtypeList[0];
          this.allcashtypeList.splice(0);
          var tempObj = {};
          tempObj.typeId = 0;
          tempObj.typeName = "ALL";
          this.allcashtypeList.push(tempObj);
          this.allcashtypeList.push(...response);
          this.allcashtype = this.allcashtypeList[0]; 
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    getIncomeListMethod: function () {
      billService
        .getIncomeTypes()
        .then((response) => {
          this.incometypeList.push(...response);
          this.incometype = this.incometypeList[0];
          this.allincometypeList.splice(0);
          var tempObj = {};
          tempObj.itypeId = 0;
          tempObj.description = "ALL";
          this.allincometypeList.push(tempObj);
          this.allincometypeList.push(...response);
          this.allincometype = this.allincometypeList[0];
          this.getSearch();
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    },
    formatDate(fromPicker) {
      const [year, month, day] = fromPicker.split("-");
      return `${day}-${month}-${year}`;
    },
    formatPicker(receivedDate) {
      const [day, month,year ] = receivedDate.split("-");
      return `${year}-${month}-${day}`;
    },
    getSearch: function () {
      billService
              .getIncome(this.fromDate,this.toDate,this.allincometype.itypeId,this.allcashtype.typeId,this.branch.branchId)
              .then((data) => {
                this.incomeList.splice(0);
                var tempSize = data.length;
          for (var i = 0; i < tempSize; i++) {
            var tempObj = data[i];
            let index = i + 1;
            if (index == tempSize) {
              this.totalBalance = tempObj.total;
            } else {
              this.incomeList.push(tempObj);
            }
          }
              })
              .catch((error) => {
                this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
              });
    },
    getBalanceMethod:function(){
      billService
        .getBalance(this.cashtype.typeId)
        .then((response) => {
            this.balance = response;
        })
        .catch((error) => {
          this.$swal("မအောင်မြင်ပါ", error.response.data.message, "error");
        });
    }
  },
  watch: {
    fromPicker() {
      this.fromDate = this.formatDate(this.fromPicker);
    },
    toPicker() {
      this.toDate = this.formatDate(this.toPicker);
    },
     cashtype(){
      if(this.cashtype.unitAmount != undefined){
          this.unitAmount = this.cashtype.unitAmount;
      }
      this.amount = Number(this.oneUnit)*Number(this.unitAmount);
      this.getBalanceMethod();
    },
    receivePicker() {
      this.receiveDate = this.formatDate(this.receivePicker);
    },
    oneUnit(){
        this.amount = Number(this.oneUnit)*Number(this.unitAmount);
    },
  },
  components: {},
};
</script>
<style scoped>
.v-data-table td,
.v-data-table th {
  padding: 0 0px !important;
}
.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
    width: 100%;
    padding: 0px;
}
</style>